<template>
    <div id="navigation">
        <v-snackbar id="error" v-if="snackbarEnabled" v-model="error" timeout="5000" color="red" multi-line min-width="500" min-height="500" top>
            {{ log }}
        </v-snackbar>
        <v-snackbar id="snackbar" v-if="snackbarEnabled" v-model="snackbar" timeout="5000">{{ msg }}</v-snackbar>
        <v-app-bar app color="primary">
            <v-toolbar-items>
                <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

                <v-btn
                    v-for="route in toolbar_routes()"
                    text
                    :to="open(route)"
                    :key="route.path"
                    class="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex"
                    :title="route.name.toUpperCase()"
                >
                    <v-icon> {{ route.icon }} </v-icon>
                </v-btn>
                <v-btn text @click="snackbarEnabled = !snackbarEnabled" class="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex"
                    ><v-icon v-if="snackbarEnabled"> mdi-information </v-icon><v-icon v-if="!snackbarEnabled"> mdi-information-outline </v-icon></v-btn
                >
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn text v-if="$store.state.server.session.user" v-on:click="$root.login_dialog.open()" class="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex">{{
                $store.state.server.session.user.name
            }}</v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed temporary class="navigation-sidebar">
            <v-list nav dense>
                <v-list-item-group active-class="primary--text">
                    <v-list-item>
                        {{ version }}
                    </v-list-item>
                    <v-list-item v-for="route in sidebar_routes()" :to="open(route)" :key="route.path">
                        <v-icon>{{ route.icon }}</v-icon>
                        <v-list-item-title class="text-uppercase"> {{ route.name }}</v-list-item-title>
                    </v-list-item>

                    <language_selector></language_selector>

                    <v-list-item v-for="(b, logging) in logging_routes()" @click="open_uri(logging + '.log')">
                        <v-icon>mdi-memory</v-icon>
                        <v-list-item-title> {{ logging }}.log</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
// we have 3 sources for navigation, toolbar, sidebar, home

import language_selector from "@/multilanguage-components/LanguageSelector.vue";

export default {
    name: "navigation",
    props: {},
    data: function () {
        return {
            drawer: false,
            debug: ß.DEBUG,
            error: false,
            snackbar: false,
            snackbarEnabled: true,
            log: "",
            msg: "",
            version: ß.BUILD_VERSION + " " + ß.DATE + " " + ß.TIME,
        };
    },
    components: {
        language_selector,
    },
    methods: {
        logout() {},
        open_editor() {
            window.open(ß.EDITOR_SITELINK, "_blank");
        },

        open_readme() {
            window.open("https://" + ß.HOSTNAME + "/README.html", "_blank");
        },
        open_uri(uri) {
            window.open("https://" + ß.HOSTNAME + "/" + uri, "_blank");
        },
        open(route) {
            if (route.open) return route.open;
            return route.path.split(":")[0].split("*")[0];
        },
        sidebar_routes() {
            const session = this.$store.state.server.session;
            if (!session.usecases) return [];
            const routes = this.$router.options.routes.filter(function (route) {
                if (!route.sidebar) return false;
                if (!route.usecase) return true;
                if (route.usecase) if (session.usecases[route.usecase] === true) return true;
                return false;
            });

            return routes.sort((a, b) => a.path.localeCompare(b.path));
        },
        toolbar_routes() {
            const session = this.$store.state.server.session;
            //console.log("routes", this.$router.options.routes);
            //console.log("user", user);
            if (!session.usecases) return [];
            const routes = this.$router.options.routes.filter(function (route) {
                if (!route.toolbar) return false;
                if (!route.usecase) return true;
                if (route.usecase) if (session.usecases[route.usecase] === true) return true;
                return false;
            });
            return routes.sort((a, b) => a.path.localeCompare(b.path));
        },
        logging_routes() {
            const session = this.$store.state.server.session;
            if (!session.logging) return [];
            return session.logging;
        },
    },
    sockets: {
        message(msg) {
            this.msg = msg;
            this.snackbar = true;
        },
        error(msg) {
            this.log = msg;
            this.error = true;
        },
    },
    watch: {
        "$store.state.server.session.user.theme"(nv) {
            if (nv) console.log("$store.state.server.session.user.uses", nv);
        },
    },
};
</script>

<style>
#snackbar {
    padding-bottom: 50px !important;
}
#navigation {
    z-index: 100;
}
</style>
