<template>
    <keep-alive><component :is="component" :data="data" v-if="component" :key="path" /></keep-alive>
</template>
<script>
// <keep-alive></keep-alive> is optional
/* 

@DOC this module is based on https://medium.com/front-end-weekly/webpack-and-dynamic-imports-doing-it-right-72549ff49234
The basic concept is, you may get the list of files from ß.CLOUDDIR, containing all path's.
In a vue js code: ```import vueInclude from "@/vue-include.vue";```
In the vue html: ```<vue-include :path="/some/relative/path" />```

*/


    
import "@/boilerplate/vue-include-files.js";

export default {
    name: "vue-include",
    props: ["data", "path"],
    data() {
        return {
            component: null,
        };
    },
    mounted() {
        // first render
        this.load();
    },
    methods: {
        load() {
            this.component = null;
            // we must have the prop to start with
            if (!this.path) return null;
            // we do our best on the boilerplate side to make sure this is a valid path
            if (!ß.VUE_INCLUDE_FILES[this.path]) return null;
            // do the js magick
            let fn = () => import("@/vue-include" + this.path);
            fn()
                .then(() => {
                	// import resolves to a promise
                    this.component = () => fn();
                })
                .catch(() => {
                	// something went wrong
                    this.component = () => import("@/vue-include/error.vue");
                });
        },
    },
    watch: {
        path: function (newVal, oldVal) {
            // the value of the prop changed
            this.load();
        },
        data: function (newVal, oldVal) {
            // the optional data value changed
            this.load();
        },
    }
};
</script>
<style>

</style>
