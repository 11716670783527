<template>
    <v-dialog v-model="dialog" @click:outside="close" min-width="400" max-width="800">
        <v-card class="pa-5 doc-card" :key="doc._id" @keydown.enter.prevent="save()">
            <v-card-title>
                <h2 class="text-uppercase">{{ db }}</h2>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close"><v-icon> mdi-close </v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <p class="text-left" :title="getDetails()">
                    {{ doc._id }}
                </p>
                <vuetiform ref="vuetiform" v-model="doc" :format="$store.getters['database/getDatabaseFormat'](db)" :key="doc._id" v-if="$store.getters['database/getDatabaseFormat'](db)" />
            </v-card-text>
            <v-card-actions class="dialog-actions">
                <v-btn v-if="doc._id && removeEnabled()" class="mx-2" fab color="primary" @click="remove()"> <v-icon> mdi-delete </v-icon> </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mx-2" fab color="primary" @click="save()" v-if="saveEnabled()"> <v-icon> mdi-database-check </v-icon> </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
// doc: {{doc}}
import Vuetiform from "@/vuetiform-components/vuetiform.vue";

export default {
    name: "database-dialog",
    // doc is passed as a prop, as an object
    // value is dialog open/closed
    props: ["db", "document", "value"],
    data: function () {
        return {
            key: 0,
            dialog: this.value,//false,
            doc: { ...this.document},
        };
    },
    components: { Vuetiform },
    methods: {
        save() {
            let _this = this;

            if (this.$refs.vuetiform.validate()) {
                let payload = {};
                // compose the payload
                payload.db = _this.db;
                payload.op = "create";
                payload.doc = _this.doc;
                
                if (_this.doc._id) payload.op = "findOneAndUpdate";

                // send the event and close the dialog once the answer is ack
                this.$socket.client.emit("database-operation", payload, function (result) {
                    _this.close();
                    if (result.error) alert(result.error);
                });
                return;
            }
        },
        remove() {
            let _this = this;
            let payload = {};
            payload.db = _this.db;
            payload.op = "findOneAndRemove";
 			payload.doc = _this.doc;
            
            this.$socket.client.emit("database-operation", payload, function (result) {
                _this.close();
                if (result.error) alert(result.error);
            });
        },
        close() {
            this.key++;
            this.dialog = false;
            this.$emit("input", false);
        },
        getDetails() {
        	return ("# " + new Date(this.doc.createdAt).toLocaleString() || 'unknown') + '\n' + ("@ " + new Date(this.doc.updatedAt).toLocaleString() || 'unknown');
        },
        saveEnabled() {
            const db = this.db;
        	const dba = this.$store.state.server.session.databases;
            if (!dba) return true;

            if (this.doc.comment !== undefined) if (dba[db].comment) return true;
            if (dba[db].modify) return true;
            
            return false;
        },
        removeEnabled() {
            const db = this.db;
        	const dba = this.$store.state.server.session.databases;
            if (!dba) return true;

            if (dba[db].modify) return true;
            
            return false;
        }
    },
    watch: {
        value: function () {
            this.key++;
            this.dialog = this.value;
        },
    },
};
</script>

<style scoped>
.doc-card {
    padding-top: 20px;
}
.dialog-actions {
    padding: 20px;
}
</style>
