<template>
    <v-card class="pa-5" flat>
        <v-toolbar flat color="primary" dark>
            <v-toolbar-title>{{ label || value.length + " ##&en files ##&hu file ## " }} {{ error }}</v-toolbar-title>
        </v-toolbar>

        <v-progress-linear :indeterminate="in_progress" color="primary"></v-progress-linear>

        <v-chip v-for="(e, i) in value" class="ma-2" text-color="black" @click="onClick(e)" :close="remove_enabled" @click:close="onClose(e)" :key="i">
            <v-avatar left>
                <v-icon v-if="leftbox_enabled">mdi-arrow-left-box</v-icon>
                <v-icon v-if="!leftbox_enabled">mdi-file</v-icon>
            </v-avatar>
            {{ e }}
        </v-chip>
    </v-card>
</template>
<script>
import updateFilesWith from "@/vuetifiles-components/vuetifiles-upload/updateFilesWith.js";
import getServerFiles from "@/vuetifiles-components/vuetifiles-upload/getServerFiles.js";
import getPath from "@/vuetifiles-components/vuetifiles-upload/getPath.js";

export default {
    name: "vuetifiles-list",
    //props: ["value", "label", "path"],
    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        label: {
            type: String,
            default: null,
        },
        path: {
            type: String,
            default: "/",
        },
        // path prefix and postfix gives a better programatic access for the file path
        path_prefix: {
            type: String,
            default: '',
        },
        path_postfix: {
            type: String,
            default: '',
        },
    },
    data: function () {
        return {
            in_progress: false,
            error: null,
        };
    },
    created() {
        this.getServerFiles();
    },
    methods: {
        getPath,
        getServerFiles,
        updateFilesWith,
        onClick(file) {
            window.open("/" + this.getPath() + "/" + file, "_blank").focus();
        },
    },
};
</script>
