var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.assets,"items-per-page":-1,"search":_vm.search,"item-key":"name","group-by":"location","show-group-by":"","show-select":"","fixed-header":"","height":_vm.max_height},on:{"dblclick:row":_vm.editItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search ...","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"dark":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-map-marker ")])],1)]}}]),model:{value:(_vm.set_location_dialog),callback:function ($$v) {_vm.set_location_dialog=$$v},expression:"set_location_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" SET ")]),_c('v-card-text',[(_vm.set_location_dialog)?_c('v-autocomplete',{attrs:{"items":Object.values(_vm.$store.state.database.locations.data),"item-text":"name","item-value":"_id","label":"location"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}):_vm._e(),_c('v-textarea',{attrs:{"label":"comment"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.set_location_dialog = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.setLocation()}}},[_vm._v(" OK ")])],1),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.remove_assettags_dialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1)],1),(_vm.$store.state.database.asset_tags)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary darken-2","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-tag-plus ")])],1)]}}],null,false,573890466),model:{value:(_vm.add_assettags_dialog),callback:function ($$v) {_vm.add_assettags_dialog=$$v},expression:"add_assettags_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Add assettags "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.add_assettags_dialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},_vm._l((Object.values(_vm.$store.state.database.asset_tags.data)),function(i){return _c('v-checkbox',{attrs:{"label":i.name,"color":"red","value":i._id,"hide-details":""},model:{value:(_vm.add_asset_tags),callback:function ($$v) {_vm.add_asset_tags=$$v},expression:"add_asset_tags"}})}),1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.addAssettags()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-tag-plus ")])],1)],1)],1)],1):_vm._e(),(_vm.$store.state.database.asset_tags)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary darken-2","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-tag-minus ")])],1)]}}],null,false,3760227700),model:{value:(_vm.remove_assettags_dialog),callback:function ($$v) {_vm.remove_assettags_dialog=$$v},expression:"remove_assettags_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Keep assettags "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.remove_assettags_dialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},_vm._l((Object.values(_vm.$store.state.database.asset_tags.data)),function(i){return _c('v-checkbox',{attrs:{"label":i.name,"color":"red","value":i._id,"hide-details":""},model:{value:(_vm.remove_asset_tags),callback:function ($$v) {_vm.remove_asset_tags=$$v},expression:"remove_asset_tags"}})}),1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.removeAssettags()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-tag-minus ")])],1)],1)],1)],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('database-document-dialog',{key:_vm.key,attrs:{"db":"assets","document":_vm.document},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }