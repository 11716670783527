
import * as axios from "__axios";

  

export default function onText(txt) {
    this.in_progress = true;
    let formData = new FormData();
    formData.append("path", this.getPath());
    formData.append("txt", txt);
    axios
        .post("/vuetifiles-from-paste.json", formData)
        .then((response) => {
            if (response.status === 200) this.updateFilesWith(response.data);
            this.in_progress = false;
        })
        .catch((error) => {
            console.log(error);
            this.error = error;
            this.in_progress = false;
        });
}
