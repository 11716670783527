export default function getDataset(that) {
    if (!that.$store.getters["database/hasDatabase"](that.sortimenter)) return [];
    if (!that.$store.getters["database/hasDatabase"](that.db)) return [];

    const placeholder = { _id: undefined, name: "??", show: true };

    const viewlist = that.viewlist;
    const rowslist = [];

    const sortiment = that.$store.getters["database/getDatabaseArrayData"](that.sortimenter) || [];
    sortiment.forEach((e) => {
        if (viewlist.filter((x) => x._id === e._id && x.show === true).length > 0) rowslist.push(e);
    });

    const eventlogdata = that.$store.getters["database/getDatabaseArrayData"](that.db);
    const relevantdata = [];

    eventlogdata.forEach((e) => {
        if (e.date === undefined) return;
        if (e.assets === undefined) return;
        if (e.location === undefined) return;

        e.assets.forEach((a) => {
            const o = {
                id: e._id,
                asset: a,
                date: e.date,
                location: e.location,
                mission: e.mission,
                color: "#33C",
            };
            if (that.$store.state.database.assets.data[a]) {
                o.name = that.$store.state.database.assets.data[a].name;
                if (e.mission) if (that.$store.state.database.missions.data[e.mission]) o.name += " @" + that.$store.state.database.missions.data[e.mission].name;
                relevantdata.push(o);
            }
        });
    });

    relevantdata.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
    });

    const assets = new Set(relevantdata.map((e) => e.asset));
    assets.forEach((a) => {
        const assetdata = relevantdata.filter((e) => (e.asset === a));
        assetdata.forEach(function (point, index) {
            const next = assetdata[index + 1];
            const from = new Date(point.date).getTime();
 			let until = new Date().getTime();
            if (next) until = new Date(next.date).getTime();
            point.duration = Math.floor((until - from) / 1000);
            return;
        });
    });

    that.dataset = rowslist
        .map((l) => {
            // here l is a location object
            const o = { ...l };
            let count = 0;
            // sortimenter property
            o.items = relevantdata
                .filter((d) => d[that.property] === l._id)
                .map((d) => {
                    d.z = count++;
                    return d;
                });

            return o;
        })
        .filter((e) => e.items.length > 0);

    return;
}
