<template>
    <v-form ref="form" lazy-validation>
        <vuetiform-element v-model="model" :format="format" @input="updateModel" />
    </v-form>
</template>

<script>
import VuetiformElement from "@/vuetiform-components/vuetiform-element.vue";
//
export default {
    name: "vuetiform",
    // again, we must have the vuetify components ready to use for dynamic components
    components: { VuetiformElement },
    // me must get a format, and a value, where value is the v-model we work with
    props: ["format", "value"],

    data: function () {
        return {
            // the internal v-model
            model: { ...this.value },
        };
    },
    methods: {
        // this will make sure we have two-way data binding
        updateModel() {
            let model = { ...this.value, ...this.model };
            this.$emit("input", model);
        },
        // these three form methods are by default in v-form, so we use them here and make them available the same way
        reset() {
            return this.$refs.form.reset();
        },
        resetValidation() {
            return this.$refs.form.resetValidation();
        },
        validate() {
            return this.$refs.form.validate();
        },
    },
};
</script>
