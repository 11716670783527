<template>
    <v-card v-resize="onResize">
        <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="assets"
            :items-per-page="-1"
            :search="search"
            item-key="name"
            group-by="location"
            show-group-by
            @dblclick:row="editItem"
            show-select
            fixed-header
            :height="max_height"
            v-model="selected"
        >
            <template v-slot:top>
                <v-text-field v-model="search" label="Search ..." class="mx-4" clearable></v-text-field>
                <v-spacer></v-spacer>
                <div class="text-center">
                    <v-dialog v-model="set_location_dialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark rounded class="mx-2" v-bind="attrs" v-on="on"><v-icon dark> mdi-map-marker </v-icon></v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2"> SET </v-card-title>

                            <v-card-text>
                                <v-autocomplete
                                    v-if="set_location_dialog"
                                    v-model="location"
                                    :items="Object.values($store.state.database.locations.data)"
                                    item-text="name"
                                    item-value="_id"
                                    label="location"
                                ></v-autocomplete>
                                <v-textarea label="##&en comment ##&hu komment ##" v-model="comment" />
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-btn color="primary" text @click="set_location_dialog = false"> Cancel </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="setLocation()"> OK </v-btn>
                            </v-card-actions>
                            <v-btn color="primary" text @click="remove_assettags_dialog = false"> <v-icon dark> mdi-close </v-icon> </v-btn>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="add_assettags_dialog" width="500" v-if="$store.state.database.asset_tags">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-2" dark class="mx-2" v-bind="attrs" v-on="on"><v-icon dark> mdi-tag-plus </v-icon></v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                ##&en Add assettags ##&hu Eszközcimkék hozzáadás ## <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="add_assettags_dialog = false"> <v-icon dark> mdi-close </v-icon> </v-btn>
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-checkbox
                                            v-for="i in Object.values($store.state.database.asset_tags.data)"
                                            v-model="add_asset_tags"
                                            :label="i.name"
                                            color="red"
                                            :value="i._id"
                                            hide-details
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="addAssettags()"> <v-icon dark> mdi-tag-plus </v-icon> </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="remove_assettags_dialog" width="500" v-if="$store.state.database.asset_tags">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-2" dark class="mx-2" v-bind="attrs" v-on="on"><v-icon dark> mdi-tag-minus </v-icon></v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                ##&en Keep assettags ##&hu Eszközcimkék megtartása ##
                                <v-spacer></v-spacer><v-btn color="primary" text @click="remove_assettags_dialog = false"> <v-icon dark> mdi-close </v-icon> </v-btn>
                            </v-card-title>

                            <v-card-text>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-checkbox
                                                v-for="i in Object.values($store.state.database.asset_tags.data)"
                                                v-model="remove_asset_tags"
                                                :label="i.name"
                                                color="red"
                                                :value="i._id"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="removeAssettags()"> <v-icon dark> mdi-tag-minus </v-icon> </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </template>
        </v-data-table>
        <database-document-dialog db="assets" :document="document" v-model="dialog" :key="key" />
    </v-card>
</template>

<script>
import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";

export default {
    name: "Assets",
    route: { path: "/assetstlist", icon: "mdi-tag-multiple", toolbar: true, sidebar: true, homepage: true, usecase: "assetstlist", open: "/assetstlist" },
    data() {
        return {
            max_height: Math.floor(window.innerHeight - 230),

            document: null,
            dialog: false,
            key: null,
            selected: [],
            set_location_dialog: false,
            add_assettags_dialog: false,
            remove_assettags_dialog: false,
            location: "60c831ff8194b6421a0a6491",
            add_asset_tags: [],
            remove_asset_tags: [],

            search: "",
            comment: "",
            headers: [
                {
                    text: "Assets",
                    align: "start",
                    value: "name",
                    groupable: false,
                    width: 30,
                },

                { text: "Location", value: "location", groupable: true, width: 10 },
                { text: "Mission", value: "mission", groupable: false, width: 20 },
                { text: "Asset-tags", value: "asset_tags", groupable: false, width: 20 },
                { text: "Barcodes", value: "barcodes", groupable: false, width: 20 },
            ],
            assets: [],
        };
    },
    components: { DatabaseDocumentDialog },
    mounted() {
        this.update();
    },
    methods: {
        onResize() {
            if (window.innerHeight > 230) this.max_height = Math.floor(window.innerHeight - 230);
        },
        update() {

            if (!this.$store.state.database) return console.log("Assets update - no database");
            if (!this.$store.state.database.assets) return console.log("Assets update - no database assets");

            this.assets = Object.values(this.$store.state.database.assets.data).map((a) => {
                const o = { ...a };
                o.name = a.name;
                o._id = a._id;

                if (a.location) if (this.$store.state.database.locations.data[a.location]) o.location = this.$store.state.database.locations.data[a.location].name;
                if (a.mission) if (this.$store.state.database.missions.data[a.mission]) o.mission = this.$store.state.database.missions.data[a.mission].name;

                o.asset_tags = "";
                if (a.asset_tags)
                    a.asset_tags.forEach((e) => {
                        if (this.$store.state.database.asset_tags.data[e]) o.asset_tags += " " + this.$store.state.database.asset_tags.data[e].name;
                    });

                o.barcodes = "";
                if (a.barcodes)
                    a.barcodes.forEach((e) => {
                        if (this.$store.state.database.barcodes.data[e]) o.barcodes += " " + this.$store.state.database.barcodes.data[e].name;
                    });

                return o;
            });

            if (this.remove_asset_tags.length < 1)
                if (this.$store.state.database.asset_tags) if (this.$store.state.database.asset_tags.data) this.remove_asset_tags = Object.keys(this.$store.state.database.asset_tags.data);
        },
        editItem(mouse, event) {
            const a = event.item;
            this.key = a._id;
            this.document = this.$store.state.database.assets.data[a._id];
            this.dialog = true;
        },
        setLocation() {
            const payload = {};
            payload.assets = this.selected.map((e) => e._id);
            payload.location = this.location;
            payload.comment = this.comment;

            this.$socket.client.emit("set-assets-location", payload, () => {
                this.comment = "";
                this.update();
                this.set_location_dialog = false;
            });
        },
        addAssettags() {
            const payload = {};
            payload.assets = this.selected.map((e) => e._id);
            payload.asset_tags = this.add_asset_tags;
            payload.comment = this.comment;
            payload.add_tags = true;
            this.$socket.client.emit("set-assettags", payload, () => {
                this.comment = "";
                this.update();
                this.add_assettags_dialog = false;
            });
        },
        removeAssettags() {
            const payload = {};
            payload.assets = this.selected.map((e) => e._id);
            payload.asset_tags = this.remove_asset_tags;
            payload.comment = this.comment;
            payload.remove_tags = true;
            this.$socket.client.emit("set-assettags", payload, () => {
                this.comment = "";
                this.update();
                this.remove_assettags_dialog = false;
            });
        },
    },
    created() {},
    watch: {
        "$store.state.database.assets": function (newVal, oldVal) {
            this.update();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home {
}
</style>
