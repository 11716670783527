<template>
    <v-data-table
        :headers="headers"
        :items="value"
        :footer-props="{
            'items-per-page-options': [25, 50, 100, 500, -1],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
        }"
        class="elevation-1"
        dense
    >
    </v-data-table>
</template>

<script>
    
import headersByFormat from "@/database-components/headersByFormat.js";
    

export default {
    name: "database-csv-upload-table",
    props: ["db", "value"],
    data() {

        const format = this.$store.getters['database/getDatabaseFormat'](this.db);

        return {
            headers: headersByFormat(format),
            search: "",
            selected: [],
        };
    }
};
</script>
