<template>
    <div v-if="getItems().length > 0">
        <p id="label" class="font-weight-thin text-left">{{ label }}</p>
        <v-chip-group v-model="selection" :multiple="multiple" :active-class="readonly ? '' : 'primary--text'">
            <v-chip v-for="(i, x) in getItems()" :key="x" @click="click(i)">
                {{ stringFormat(i) }}
            </v-chip>
        </v-chip-group>
    </div>
</template>

<script>
export default {
    // note that value is not really processed for now.

    name: "vuetiform-chipgroup",
    props: ["value", "label", "items", "multiple", "readonly", "action"],
    data: function () {
        return {
            selection: null,
        };
    },
    methods: {
        update() {
            this.$emit("input", this.selection);
        },
        getItems() {
            if (this.items) return this.items;
            if (this.value) return this.value;
            return [];
        },
        stringFormat(i) {
            if (typeof i === "object") if (i.text !== undefined) return i.text;
            return i;
        },
        click(e) {
            if (typeof this.action === 'function') this.action(e);
            else navigator.clipboard.writeText(this.stringFormat(e));
            
            if (this.readonly) return;
            this.$emit("input", this.selection);
        },
    },
};
</script>
<style scoped>
#label {
    margin-bottom: 0;
}
</style>