
import * as axios from "__axios";

  

export default function openImage(file) {
    axios
        .get("/" + this.getPath() + "/" + file, {
            responseType: "arraybuffer",
            headers: {
                Accept: "image/*",
            },
        })
        .then((response) => {
            console.log(response);
            // response.data is an empty object
            const blob = new Blob([response.data], {
                type: "image/*",
            });
            var URLObj = window.URL || window.webkitURL;
            this.img_src = URLObj.createObjectURL(blob);
            this.filename = file || "paste-" + new Date().toJSON().replace(/\D/g, "") + ".jpg";
            this.view = true;
        });
}
