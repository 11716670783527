<template>
    <v-card-text>
        <v-container grid-list-md> <form_for_name @return="action" v-bind:btn_title="'##&en Submit ##&hu Küldés ##'" /> </v-container>
        <v-container>
            <v-alert id="alert" v-show="msg" :value="true" :type="type" transition="fade" class="text-sm-left">{{ msg }}</v-alert>
            <v-progress-circular v-show="progress" :size="50" color="primary" indeterminate></v-progress-circular>
        </v-container>
    </v-card-text>
</template>

<script>
import * as axios from "__axios";

import form_for_name from "@/login-components/FormForName.vue";

export default {
    name: "login-card-name",
    data() {
        return {
            name: null,
            progress: false,
            msg: false,
            type: "info",
        };
    },
    components: {
        form_for_name,
    },
    methods: {
        action(arg) {
            //this.$refs.form.validate();
            this.progress = true;
            this.msg = false;
            this.type = "info";
            this.post_name(arg);
        },
        post_name(name) {
            this.type = "info";
            var _this = this;
            axios({
                method: "post",
                url: "/post-name.json",
                data: { name: name },
            })
                .then(function (response) {
                    _this.progress = false;

                    console.log(response.data);
                    if (response.data === "OK") {
                        _this.msg = "##&en Thank you. ##&hu Köszönöm. ##";
                        _this.$store.dispatch("server/load_session");
                        //_this.$router.push('/welcome');
                        _this.$emit("dialog_handler", "selector");
                        return;
                    }

                    _this.type = "info";
                    _this.msg = response.data;
                })
                .catch((error) => {
                    _this.progress = false;
                    _this.type = "error";
                    _this.msg = "##&en Network error. ##&hu Hálózati hiba ##";
                    // eslint-disable-next-line
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
#alert {
    opacity: 0.6;
}
</style>
