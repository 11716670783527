<template>
    <v-card class="pa-5" flat v-if="path">
        <v-toolbar flat color="primary" dark :title="getPath()">
            <v-toolbar-title :title="path">{{ label || value.length + " file " }} {{ error }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-none" rounded depressed :disabled="in_progress" @click="onLeftBoxClick" title="Toggle Move Left">
                <v-icon left>mdi-arrow-left-box</v-icon>
            </v-btn>
            <v-btn color="primary" class="text-none" rounded depressed :disabled="in_progress" @click="onToggleCloseClick" title="Toggle Close">
                <v-icon left>mdi-close-circle-multiple</v-icon>
            </v-btn>
            <!--
            <v-btn color="primary" class="text-none" rounded depressed :disabled="in_progress" @click="onImageUploadClick" title="Upload Image">
                <v-icon left>mdi-file-image</v-icon>
            </v-btn>
            <input ref="image" class="d-none" type="file" accept="image/*" @change="onFileChanged" />
			-->
            <v-btn color="primary" class="text-none" rounded depressed :disabled="in_progress" @click="onCropUploadClick" title="Upload Image and Crop">
                <v-icon left>mdi-image-edit</v-icon>
            </v-btn>
            <input ref="crop" class="d-none" type="file" accept="image/*" @change="onCropImageChanged" />
            <v-btn color="primary" class="text-none" rounded depressed :disabled="in_progress" @click="onFilesUploadClick" title="Upload Files">
                <v-icon left>mdi-file-multiple</v-icon>
            </v-btn>
            <input multiple ref="multiple" class="d-none" type="file" @change="onFileChanged" />
            <input maxlength="0" style="border: 2px solid white; width: 20px; border-radius: 4px" @paste="onPaste" title="copy-paste URL/txt for uplaod" />
        </v-toolbar>
        <v-progress-linear :indeterminate="in_progress" color="primary"></v-progress-linear>
        <v-chip v-for="(e, i) in value" class="ma-2" text-color="black" @click="onClick(e)" :close="remove_enabled" @click:close="onClose(e)" :key="i">
            <v-avatar left>
                <v-icon>{{ icon(e) }}</v-icon>
            </v-avatar>
            {{ e }}
        </v-chip>
        <v-dialog v-model="dialog" fullscreen>
            <v-card>
                <v-card-title>
                    {{ getPath() }}/{{ filename }} <v-spacer></v-spacer> <v-btn color="primary" text @click="dialog = false"><v-icon> mdi-close </v-icon></v-btn>
                </v-card-title>
                <cropper ref="cropper" :src="img_src" v-bind="settings" style="max-with: 100px" />
                <v-card-actions>
                    <v-select :items="cropper_settings" :menu-props="{ top: true, offsetY: true }" label="Crop" v-model="settings"></v-select>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab color="primary" @click="previewImage()" title="Preview"><v-icon> mdi-image-frame </v-icon></v-btn>
                    <v-btn class="mx-2" fab color="primary" @click="saveImage()" title="Save"><v-icon> mdi-image-edit </v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="view" fullscreen>
            <v-card>
                <v-card-title>
                    <v-btn color="primary" text @click="" :href="'/' + getPath() + '/' + filename" target="_blank"><v-icon> mdi-tab </v-icon></v-btn>
                    <v-btn color="primary" text @click="" :href="'/' + getPath() + '/' + filename" download><v-icon> mdi-download </v-icon></v-btn>
                    {{ getPath() }}/{{ filename }} <v-spacer></v-spacer> <v-btn color="primary" text @click="view = false"><v-icon> mdi-close </v-icon></v-btn>
                </v-card-title>
                <div class="popup-image" :style="getStyle()" @click="view = false"/>
            </v-card>
            
        </v-dialog>
    </v-card>
</template>
<script>

import * as axios from "__axios";

  

// images can be cropped with this
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// pass settings from external file
import cropper_settings from "@/vuetifiles-components/vue-advanced-cropper-settings.js";

import onPaste from "@/vuetifiles-components/vuetifiles-upload/onPaste.js";
import onText from "@/vuetifiles-components/vuetifiles-upload/onText.js";
import onImageBlob from "@/vuetifiles-components/vuetifiles-upload/onImageBlob.js";
import getPath from "@/vuetifiles-components/vuetifiles-upload/getPath.js";
import getServerFiles from "@/vuetifiles-components/vuetifiles-upload/getServerFiles.js";
import updateFilesWith from "@/vuetifiles-components/vuetifiles-upload/updateFilesWith.js";
import onFileChanged from "@/vuetifiles-components/vuetifiles-upload/onFileChanged.js";

import uploadFormData from "@/vuetifiles-components/vuetifiles-upload/uploadFormData.js";
import onCropImageChanged from "@/vuetifiles-components/vuetifiles-upload/onCropImageChanged.js";
import previewImage from "@/vuetifiles-components/vuetifiles-upload/previewImage.js";
import saveImage from "@/vuetifiles-components/vuetifiles-upload/saveImage.js";
import onClick from "@/vuetifiles-components/vuetifiles-upload/onClick.js";
import openImage from "@/vuetifiles-components/vuetifiles-upload/openImage.js";
import viewImage from "@/vuetifiles-components/vuetifiles-upload/viewImage.js";
import onMoveLeft from "@/vuetifiles-components/vuetifiles-upload/onMoveLeft.js";
import onClose from "@/vuetifiles-components/vuetifiles-upload/onClose.js";
import removeServerFile from "@/vuetifiles-components/vuetifiles-upload/removeServerFile.js";

import isImageByExtension from "@/vuetifiles-components/vuetifiles-upload/isImageByExtension.js";

export default {
    name: "vuetifiles-upload",
    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        label: {
            type: String,
            default: null,
        },
        path: {
            type: String,
            default: null,
        },
        // path prefix and postfix gives a better programatic access for the file path
        path_prefix: {
            type: String,
            default: "",
        },
        path_postfix: {
            type: String,
            default: "",
        },
        cropper: {
            type: Array,
            default: () => cropper_settings,
        },
    },
    data: function () {
        return {
            filename: null,
            img_src: "",
            dialog: false,
            view: false,
            in_progress: false,
            cropper_settings: this.cropper,
            settings: cropper_settings[0] || {},
            error: null,
            remove_enabled: false,
            leftbox_enabled: false,
        };
    },
    created() {
        this.getServerFiles();
    },
    methods: {
        getStyle() {
            
        	return "background-image: url('/" + this.getPath() + "/" + this.filename + "')";
        },
        onPaste,
        onText,
        onImageBlob,
        getPath,
        getServerFiles,
        updateFilesWith,
        onFileChanged,
        uploadFormData,
        onCropImageChanged,
        previewImage,
        saveImage,
        onClick,
        openImage,
        viewImage,
        onMoveLeft,
        onClose,
        removeServerFile,
        isImageByExtension,
        addEventListener() {
            this.in_progress = true;
            window.addEventListener("focus", () => (this.in_progress = false), { once: true });
        },
        onLeftBoxClick() {
            this.leftbox_enabled = !this.leftbox_enabled;
        },
        onToggleCloseClick() {
            this.remove_enabled = !this.remove_enabled;
        },
        onImageUploadClick() {
            this.addEventListener();
            this.$refs.image.click();
        },
        onCropUploadClick() {
            this.addEventListener();
            this.$refs.crop.click();
        },
        onFilesUploadClick() {
            this.addEventListener();
            this.$refs.multiple.click();
        },
        icon(file) {
            if (this.leftbox_enabled) return "mdi-arrow-left-box";
            let ext = file.split(".").pop().toLowerCase();
            if (isImageByExtension(ext)) return "mdi-image-edit";
            if (ext === "pdf") return "mdi-file-pdf";
            if (ext === "wav" || ext === "mp3") return "mdi-file-music";
            if (ext === "avi" || ext === "mp4" || ext === "mkv") return "mdi-file-video";
            if (ext === "doc" || ext === "docx" || ext === "odf") return "mdi-file-word";
            if (ext === "xls" || ext === "csv" || ext === "odt") return "mdi-file-table";
            return "mdi-file";
        },
    },
    watch: {
        path: function () {
            this.getServerFiles();
        },
        path_prefix: function () {
            this.getServerFiles();
        },
        path_postfix: function () {
            this.getServerFiles();
        },
    },
};
</script>

<style scoped>
    input:focus {
        background-color: white;
    }
    /* Thx to Szemi. */
    .popup-image{
        width: 100%;
    	height: calc(100% - 62px);
    	position: absolute;
    	background-position: top;
    	background-size: contain;
    }
</style>