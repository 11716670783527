// this is an automatically generated file from the ßoilerplate framework

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_actions from '@/f0x-actions.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_barcode from '@/f0x-barcode.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_ble from '@/f0x-ble.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_nfc from '@/f0x-nfc.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_photo from '@/f0x-photo.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_session from '@/f0x-session.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import f0x_settings from '@/f0x-settings.vue';

// /srv/codepad-project/@f0x-modules/f0x/vue;
import fox from '@/fox.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import global_component from '@/global-components/global-component.vue';

// /srv/codepad-project/hangmaffia-modules/app/vue;
import App from '@/App.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import main from '@/main.vue';

// /srv/codepad-project/@vue-modules/app/vue;
import navigation from '@/navigation.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import FormForEmail from '@/login-components/FormForEmail.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import FormForName from '@/login-components/FormForName.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import FormForPassword from '@/login-components/FormForPassword.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginCardEmailLogin from '@/login-components/LoginCardEmailLogin.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginCardEmailSettings from '@/login-components/LoginCardEmailSettings.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginCardName from '@/login-components/LoginCardName.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginCardPasswordLogin from '@/login-components/LoginCardPasswordLogin.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginCardPasswordSettings from '@/login-components/LoginCardPasswordSettings.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginCardSettings from '@/login-components/LoginCardSettings.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginDialog from '@/login-components/LoginDialog.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginLayout from '@/login-components/LoginLayout.vue';

// /srv/codepad-project/@vue-modules/login/vue;
import LoginSelector from '@/login-components/LoginSelector.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_card from '@/database-components/database-card.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_csv_download from '@/database-components/database-csv-download.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_csv_upload_table from '@/database-components/database-csv-upload-table.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_csv_upload from '@/database-components/database-csv-upload.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_dialog_table from '@/database-components/database-dialog-table.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_document_dialog from '@/database-components/database-document-dialog.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_json_download from '@/database-components/database-json-download.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_json_upload from '@/database-components/database-json-upload.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database_table from '@/database-components/database-table.vue';

// /srv/codepad-project/@vue-modules/database/vue;
import database from '@/database.vue';

// /srv/codepad-project/@vue-modules/multilanguage/vue;
import LanguageSelector from '@/multilanguage-components/LanguageSelector.vue';

// /srv/codepad-project/@vue-modules/timeline/vue;
import timeline_date_selector from '@/timeline-components/timeline-date-selector.vue';

// /srv/codepad-project/@vue-modules/timeline/vue;
import timeline_viewlist_dialog from '@/timeline-components/timeline-viewlist-dialog.vue';

// /srv/codepad-project/@vue-modules/timeline/vue;
import timeline from '@/timeline-components/timeline.vue';

// /srv/codepad-project/@vue-modules/vueinclude/vue;
import dash from '@/vue-include/default/dash.vue';

// /srv/codepad-project/@vue-modules/vueinclude/vue;
import no_content from '@/vue-include/default/no-content.vue';

// /srv/codepad-project/@vue-modules/vueinclude/vue;
import error from '@/vue-include/error.vue';

// /srv/codepad-project/@vue-modules/vueinclude/vue;
import vue_include from '@/vue-include.vue';

// /srv/codepad-project/@vue-modules/vuetifiles/vue;
import vuetifiles_list from '@/vuetifiles-components/vuetifiles-list.vue';

// /srv/codepad-project/@vue-modules/vuetifiles/vue;
import vuetifiles_upload from '@/vuetifiles-components/vuetifiles-upload.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_checkboxes from '@/vuetiform-components/vuetiform-checkboxes.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_chipgroup from '@/vuetiform-components/vuetiform-chipgroup.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_date_and_time from '@/vuetiform-components/vuetiform-date-and-time.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_duration from '@/vuetiform-components/vuetiform-duration.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_element from '@/vuetiform-components/vuetiform-element.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_radios from '@/vuetiform-components/vuetiform-radios.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform_timestamp from '@/vuetiform-components/vuetiform-timestamp.vue';

// /srv/codepad-project/@vue-modules/vuetiform/vue;
import vuetiform from '@/vuetiform-components/vuetiform.vue';

// /srv/codepad-project/hangmaffia-modules/assets/vue;
import assets from '@/assets.vue';

// /srv/codepad-project/hangmaffia-modules/eventlog/vue;
import assetlog from '@/assetlog.vue';

// /srv/codepad-project/hangmaffia-modules/missions/vue;
import home from '@/home.vue';


export default {f0x_actions: f0x_actions, f0x_barcode: f0x_barcode, f0x_ble: f0x_ble, f0x_nfc: f0x_nfc, f0x_photo: f0x_photo, f0x_session: f0x_session, f0x_settings: f0x_settings, fox: fox, global_component: global_component, App: App, main: main, navigation: navigation, FormForEmail: FormForEmail, FormForName: FormForName, FormForPassword: FormForPassword, LoginCardEmailLogin: LoginCardEmailLogin, LoginCardEmailSettings: LoginCardEmailSettings, LoginCardName: LoginCardName, LoginCardPasswordLogin: LoginCardPasswordLogin, LoginCardPasswordSettings: LoginCardPasswordSettings, LoginCardSettings: LoginCardSettings, LoginDialog: LoginDialog, LoginLayout: LoginLayout, LoginSelector: LoginSelector, database_card: database_card, database_csv_download: database_csv_download, database_csv_upload_table: database_csv_upload_table, database_csv_upload: database_csv_upload, database_dialog_table: database_dialog_table, database_document_dialog: database_document_dialog, database_json_download: database_json_download, database_json_upload: database_json_upload, database_table: database_table, database: database, LanguageSelector: LanguageSelector, timeline_date_selector: timeline_date_selector, timeline_viewlist_dialog: timeline_viewlist_dialog, timeline: timeline, dash: dash, no_content: no_content, error: error, vue_include: vue_include, vuetifiles_list: vuetifiles_list, vuetifiles_upload: vuetifiles_upload, vuetiform_checkboxes: vuetiform_checkboxes, vuetiform_chipgroup: vuetiform_chipgroup, vuetiform_date_and_time: vuetiform_date_and_time, vuetiform_duration: vuetiform_duration, vuetiform_element: vuetiform_element, vuetiform_radios: vuetiform_radios, vuetiform_timestamp: vuetiform_timestamp, vuetiform: vuetiform, assets: assets, assetlog: assetlog, home: home, };