<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" fab color="primary" v-bind="attrs" v-on="on"><v-icon>fas fa-table</v-icon></v-btn>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                <h2 class="text-uppercase database-dialog-table-title">{{ items.length }} {{ db }}</h2>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false"><v-icon> mdi-close </v-icon></v-btn>
            </v-card-title>
                <database-table :db="db" :items="items" />
            <v-divider></v-divider>
        </v-card>
    </v-dialog>
</template>

<script>
import DatabaseTable from "@/database-components/database-table.vue";

export default {
    name: "database-dialog-table",
    props: ["db", "items"],
    data() {
        return {
            dialog: false,
        };
    },
    components: { DatabaseTable },
    
};
</script>
<style scoped>
.database-dialog-table-title {
    margin: 20px;
}
</style>
