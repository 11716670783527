export default function onCropImageChanged(e) {
    const file = e.target.files[0];
    const r = new FileReader();
    r.onloadend = (e) => {
        this.img_src = r.result;
    };
    r.readAsDataURL(file);

    this.dialog = true;
    this.filename = ß.convertFilename(file.name);
}
