
import * as axios from "__axios";


export default function openImage(file) {
    axios
        .get("/" + this.getPath() + "/" + file, {
            responseType: "arraybuffer",
            headers: {
                Accept: "image/*",
            },
        })
        .then((response) => {
            console.log(response);
            // response.data is an empty object
            const blob = new Blob([response.data], {
                type: "image/*",
            });
            this.onImageBlob(blob, file);
        });
}
