<template>
    <v-dialog v-model="value" @click:outside="close()" min-width="400" max-width="800">
        <v-card class="pa-5 doc-card" @keydown.enter.prevent="close()">
            <v-card-title>
                <h2 class="text-uppercase">##&en View ##&hu Nézet ##</h2>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close"><v-icon> mdi-close </v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-item-group>
                    <v-btn @click="all()">
                        <v-icon>mdi-view-list</v-icon>
                    </v-btn>

                    <v-btn @click="none()">
                        <v-icon>mdi-view-list-outline</v-icon>
                    </v-btn>

                    <v-btn @click="toggle()">
                        <v-icon>mdi-format-list-checks</v-icon>
                    </v-btn>

                </v-item-group>
                <div v-for="(elem, index) in list">
                    <v-checkbox class="checkbox" dense :label="elem.name || ''" v-model="list[index].show" @change="update()" />
                </div>
            </v-card-text>
            <v-card-actions class="dialog-actions">
                <v-spacer></v-spacer>
                <v-btn class="mx-2" fab color="primary" @click="close()"> <v-icon> mdi-close </v-icon> </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "timeline-viewlist-dialog",
    // doc is passed as a prop, as an object
    // value is dialog open/closed
    props: ["list", "value"],
    data: function () {
        return {
        };
    },
    methods: {
        close() {
            this.$emit("update");
            this.$emit("input", false);
        },
        update() {
            this.$emit("update");
        },
        all() {
            this.list.forEach((e) => (e.show = !e.show));
            this.$emit("update");
        },
        none() {
            this.list.forEach((e) => (e.show = !e.show));
            this.$emit("update");
        },
        toggle() {
            this.list.forEach((e) => (e.show = !e.show));
            this.$emit("update");
        },
    },
};
</script>

<style scoped>
.checkbox {
    margin-top: 0;
    padding-top: 0;
}
</style>
