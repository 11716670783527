<template>
    <a :download="filename" :href="download()">
        <v-btn class="mx-2" fab color="primary"> <v-icon> mdi-download-outline </v-icon></v-btn>
    </a>
</template>

<script>
export default {
    name: "database-json-download",
    props: ["db"],
    data: function () {
        return {
            filename: ß.HOSTNAME + "-" + this.db + ".json",
        };
    },
    computed: {},
    methods: {
        download() {
            if (!this.db) return "";
            if (!this.$store.getters["database/hasDatabase"](this.db)) return "";
            return "data:text/json," + encodeURIComponent(JSON.stringify(this.$store.state.database[this.db].data, null, 4));
        },
    },
};
</script>
