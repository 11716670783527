export default () => {
    return [
        { text: "1 Minute", value: 60 },
        { text: "10 Minutes", value: 600 },
        { text: "1 Hour", value: 3600 },
        { text: "12 Hours", value: 43200 },

        { text: "1 Day", value: 86400 },
        { text: "7 Days", value: 604800 },
        { text: "14 Days", value: 1209600 },
        { text: "28 Days", value: 2419200 },
        { text: "56 Days", value: 4838400 },
        { text: "1 Year", value: 31557600 },
    ];
};
