import { render, staticRenderFns } from "./f0x-settings.vue?vue&type=template&id=11be4451&scoped=true&"
import script from "./f0x-settings.vue?vue&type=script&lang=js&"
export * from "./f0x-settings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../@vue-modules/vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11be4451",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../@vue-modules/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardTitle,VCheckbox,VContainer})
