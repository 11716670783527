<template>
    <a :download="filename" :href="download()">
        <v-btn :disabled="items.length < 1" class="mx-2" fab color="primary"> <v-icon> mdi-download </v-icon></v-btn>
    </a>
</template>

<script>
import * as CSV from "csv-string";
import headersByFormat from "@/database-components/headersByFormat.js";

export default {
    name: "database-csv-download",
    props: ["db", "items"],
    data: function () {
        return {
            filename: ß.HOSTNAME + "-" + this.db + ".csv",
        };
    },
    computed: {},
    methods: {
        download() {
            // the resulting csv string
            let csvdata = "";
            const format = this.$store.getters['database/getDatabaseFormat'](this.db);

            // an array of properties, where nested objects have the dot-notation
            const fields = ["_id", "createdAt", "updatedAt"].concat(headersByFormat(format).map((e) => e.value));
            // the local version of the selected items.
            const items = this.items;

            // the csv headers should keep the dot-notation identifiers, so we can identify them in an import as well.
            csvdata += fields.join(",");
            csvdata += "\n";

            let csv = [];
            
            // line-by-line ordered
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                csv[i] = [];
                // field-by-field ordered
                for (let j = 0; j < fields.length; j++) {
                    let field = fields[j];

                    // resolve the dot notation
                    let value = { ...item };
                    // keep on diving deeper
                    for (let w of field.split("."))
                        if (value[w] !== undefined) value = value[w];
                        // or if we have no result before we reached the end of nesting, this field is undefined.
                        else value = undefined;

                    // add to our csv
                    if (value === undefined) continue; 
                                               
                    csv[i][j] = value.toString();
                }                       
            }

            csvdata += CSV.stringify(csv);
            
            // this is the download ..
            return "data:text/csv," + encodeURIComponent(csvdata);
        },
    },
};
</script>
