// @DOC Entry point of the frontend is main.js

// @VUECLI console.log("Using webpack via vue-cli");
// @VITE console.log("Using Vite");

import Vue from "vue";
import "@/main.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";

import router from "@/router.js";
import store from "@/boilerplate/store.js";

import App from "@/App.vue";

// socket.io is loaded in the index html file does not work with vue_cli - but needed for compatibility with vite
import io from "socket.io-client";

import VueSocketio from "vue-socket.io-extended";

// Added Global D3 
import * as d3 from "d3";
globalThis.d3 = d3;

// @DOC Socketio will only connect if a passport user is logged in. Override possible.

if (ß.USE_SOCKETIO) {
    const socket_options = ß.SOCKETIO_OPTIONS || {
        autoConnect: ß.SOCKETIO_AUTOCONNECT || false,
    };
    const socket = io(socket_options);
    Vue.use(VueSocketio, socket, { store });
}

if (ß.DEBUG) console.log("[app/main.js] build:", ß.BUILD_VERSION, 'debug:', ß.DEBUG);

// eslint-disable-next-line
if (ß.DEBUG) Vue.config.devtools = true;
// eslint-disable-next-line
if (ß.DEBUG) Vue.config.debug = true;

// we create $app via this plugin, to make the function $app.uri and the constant $app.url available globally from app.functions.js.
// it might get extended with others by vreating componentgroup js files that export other functions ...
// we use a generator and merge all from all modules
import functions from "@/componentgroup/functions.js";

const plugin = {
    install(Vue, options) {
        // so actually we export the name from name.functions.js to vue's global $name
        Object.keys(functions).forEach(function (fn, ix) {
            Vue.prototype["$" + fn] = functions[fn];
        });
    },
};

Vue.use(plugin);

/*
// if we use the vueinclude module, import it as vue-include component
if (ß.USE_VUEINCLUDE) {
    Vue.component("vue-include", () => import("@/vue-include.vue"));
}

// we need at least one global component TODO 
// register the global-components folder's components
const files = require.context('@/global-components', true, /\.vue$/i);
files.keys().map(key => {
     // originally used ?? operator for .. || 
     Vue.component(files(key).default.name || key.split('/').pop().split('.')[0], files(key).default);
});
*/
import vuetify from "@/plugins/vuetify.js";

var vm = new Vue({
    el: "#app",
    store,
    router,
    vuetify, // added as required in 2.0.7
    mounted() {
        this.$store.dispatch("server/load_session");
    },
    render: (h) => h(App),
});

/* @DOC
 
 Use a /static file in a template
 <img :src="$app.uri('/some.svg')" height="200px">
 
 Use an asset file in a template
 <img :src="require('@/assets/some.svg')" height="200px">

*/