<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title">
            NFC <v-spacer></v-spacer><v-btn color="primary" text @click="openDialog"><v-icon> mdi-nfc </v-icon></v-btn>
        </v-card-title>

        <v-card flat style="min-width: 300px; min-height: 700px; max-width: 500px; margin: auto; position: relative">
            <div v-for="(item, i) in $store.state.session.nfc_items" :key="i">
                <v-card @click="" hover class="ma-5" style="max-width: 450px; width: 500px; margin: auto" :color="item.selected ? 'primary' : ''" @click.native="onItemTap(item)">
                    <v-card-title class="text-center" style="display: block">{{ item.name || item.key}}</v-card-title>
                </v-card>
            </div>
        </v-card>

        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title class="headline card-title">
                    ##&en Add ## nfc record ##&hu hozzáadása ##
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="
                            dialog = false;
                            text = '';
                        "
                    >
                        <v-icon> mdi-close </v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-subtitle class="text-left">##&en Text or URL ##&hu Szöveg vagy URL ##</v-card-subtitle>

                <v-card-text> <v-text-field autofocus label="nfc" ref="nfc-text" v-model="text" @input="repairText()" v-on:keyup.enter="add()"></v-text-field></v-card-text>

                <v-card-actions>
                    <v-btn block @click="add()" color="primary"> <v-icon> mdi-plus </v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    name: "f0x_nfc",
    data() {
        return {
            dialog: false,
            title: "",
            message: "",
            okButtonText: "OK",
            cancelButtonText: "Cancel",
            text: "",
            dialog_key: null,
            dialog_input: true,
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        repairText() {
            this.text = this.text
                .replaceAll(/ö/g, "0")
                .replaceAll(/öüóőúáűíÖÜÓŐÚÉÁŰÍ/g, "")
                .replaceAll(" ", "");
        },
        add() {
            if (this.text.length > 0) this.$store.dispatch("session/addNfc", { text: this.text });
            this.text = "";
            this.dialog = false;
        },
        onItemTap(item) {
            this.$store.commit("session/toggleSelection", item);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
    text-transform: none !important;
}

.action-button {
    font-size: 20px;
}

.card-title {
    margin-bottom: 10px;
}
</style>