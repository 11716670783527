export default function getViewlist() {
    const _this = this;
    const sortimenter = this.$store.getters["database/getDatabaseArrayData"](this.sortimenter) || [];
    const viewlist = this.viewlist;
    
    sortimenter.forEach((e) => {
        if (_this.viewlist.filter((x) => x._id === e._id).length > 0) return;
		//console.log(e);
        const o = {
            name: e.name,
            _id: e._id,
            show: true,
        };
        _this.viewlist.push(o);
    });

    return;
}
