export default function updateFilesWith(arr) {
    // we use our original data at first to keep the array orders
    const files = [...this.value];
    const results = [];
    // add elements we dont have locally, but in the update
    for (let file of arr) if (!files.includes(file)) files.push(file);
    // keep only elements that we have in the update
    for (let file of files) if (arr.includes(file)) results.push(file);
        
    this.$emit("input", results);
}
