/*export default {
    default: {},
    square: { "stencil-props": { aspectRatio: 1 / 1 } },
    ar4to3: { "stencil-props": { aspectRatio: 4 / 3 } },
    ar3to4: { "stencil-props": { aspectRatio: 3 / 4 } },
    ar2to1: { "stencil-props": { aspectRatio: 2 / 1 } },
    ar1to2: { "stencil-props": { aspectRatio: 1 / 2 } },
    ar16to9: { "stencil-props": { aspectRatio: 16 / 9 } },
    ar16to10: { "stencil-props": { aspectRatio: 16 / 10 } },
};*/
export default [
    { text: "-", value: {} },
    { text: "1:1", value: { "stencil-props": { aspectRatio: 1 / 1 } } },
    { text: "4:3", value: { "stencil-props": { aspectRatio: 4 / 3 } } },
    { text: "16:9", value: { "stencil-props": { aspectRatio: 16 / 9 } } },  
    { text: "16:10", value: { "stencil-props": { aspectRatio: 16 / 10 } } },
];
