//import * as d3 from "d3";


import * as timelib from "./timeline-timelib.d3.js";

export default function ({ timefrom, timespan, total_height, dataset, width, height }) {
    function setPos(d, i) {
        return "translate(0," + i * height + ")";
    }

    const sections = d3
        .select("#timeline-backrows")
        .selectAll("svg")
        .data(dataset, (d) => d);

    sections.exit().remove();

    sections.attr("transform", setPos);

    const rows = sections
        .enter()
        .append("g")
        .attr("transform", setPos)
        .attr("id", (d, i) => "row-" + d.name);

    rows.append("rect")
        .attr("fill", "#4caf50")
        .attr("width", width)
        .attr("height", height - 10)
        .attr("fill", "#eee");

    rows.append("text")
        .attr("x", 16)
        .attr("y", height - 15)
        .text((d) => d.name.toUpperCase())
        .attr("class", "title-text");
}
