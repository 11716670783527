// when working with multiple databases, an exchange needs to take place, as conversion.
// tables and csv files are not aware of remote tables, or nested objects by default.
// this is the exchanger for vuetiform styled database format entries, honoring nested objects and Array values
// we have two arguments
// src - the Array of item objects
// _this - the context object, this of the caller
// exchange_forward, Eg. _id to name when true, name to _id when false

// evaluate(str, defpointer = {}, env = {})
import evaluate from "@/vuetiform-components/vuetiform-accessor.js";

// src / items - the input data.
// _this is  the context
// exchange_forward - if false, backward.

export default function exchange(src, _this, exchange_forward) {
    // the callers this context must have a db field.
    const db = _this.db;
    if (!db) return src;

    const format = _this.$store.getters["database/getDatabaseFormat"](db);
    if (!format) return src;

    // the return items
    // first of all, create a cloned version of items, where objects are decoupled from the source objects
    // double check thet we have really objects, if object, clone it.
    // that way we wont modify props outside of mutations, ..

    // create items with empty objects
    const items = src
        //.filter((a) => typeof a === "object")
        .map((a) => {
            return deepCopy(a);
            /*return {
                src: a,
                _id: a._id,
                createdAt: a.createdAt,
                updatedAt: a.aupdatedAt,
            };*/
        });

    // then we consider each format_object, that defines how a datafield schold look like.

    // we will dive in with a recursive function
    formatDiver([], format, function diveFunction(keys, format_object) {
        // we are in a format object representation
        // keys is an Array with the current property path of the format
        // format_object describes a datafield with its proeprties, eg. is, mandatory, label, hint ...

        if (format_object.ref) {
            
            const target = _this.$store.getters['database/getDatabaseDocumentsNameIdArray'](format_object.ref);
 
            // the exchanger object will have id keys and text values
            const xc = {};
			// forward means id to field-value, backward is field-value to id.
            if (exchange_forward) for (const e of target) if (e.value) xc[e.value] = e.text;
            if (!exchange_forward) for (const e of target) if (e.text) xc[e.text] = e.value;

            // and now apply the exchange
            // item is object with eventually nested properties
            // keys is an Array with at least one element
            // xc is a buffer that holds the values to be exchanged
            items.forEach((item) => {
                // nested part of path
                const path = Array.from(keys);
                // the final key
                const key = path.pop();
                // property of item
                let p = item;
                // we will resolve p by path
                // the nesting-step must be okay
                for (const w of path)
                    if (typeof p[w] === "object") p = p[w];
                    // otherwise the object does not have this property, thus nothing to do
                    else return;

                // we consider only string values
                if (typeof p[key] === "string") if (xc[p[key]] !== undefined) p[key] = xc[p[key]];
                // and Array of strings
                if (Array.isArray(p[key])) for (let i = 0; i < p[key].length; i++) if (typeof p[key][i] === "string") if (xc[p[key][i]] !== undefined) p[key][i] = xc[p[key][i]];
            });
        }

    });

    // now we have a copy of items, with exchanged id's to values.
    return items;
}

// recursive format diver
function formatDiver(keys, format_pointer, diveFunction) {
    // a valid format in between and endresult is an object
    if (typeof format_pointer !== "object") return;
    if (format_pointer === null) return;
    // endresult of a vuetiform style format must have an "is" property
    if (format_pointer.is !== undefined) return diveFunction(keys, format_pointer);
    // iterate through the format keys, as it is nested object.
    for (const key in format_pointer) formatDiver(keys.concat([key]), format_pointer[key], diveFunction);
}



function deepCopy(obj) {
    if (obj === undefined) return undefined;
    if (obj === null) return null;
    if (typeof obj !== "object") return obj;

    if (obj instanceof Date) {
        const copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        var copy = [];
        for (let i = 0; i < obj.length; i++) copy[i] = deepCopy(obj[i]);
        return copy;
    }
    if (obj instanceof Object) {
        const copy = {};
        for (const attr in obj) if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);

        return copy;
    }
    console.log("Unable to copy", obj);
}