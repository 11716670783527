// boilerplate generated 2024-05-19 08:42:25
import Vue from 'vue';

import Vuex from 'vuex';
import location from '@/vuex/location.js';
import session from '@/vuex/session.js';
import database from '@/vuex/database.js';
import databases from '@/vuex/databases.js';
import server from '@/vuex/server.js';
import socket from '@/vuex/socket.js';

Vue.use(Vuex)
export default new Vuex.Store({
strict: true,
  modules: {
	location,
	session,
	database,
	databases,
	server,
	socket,
	}
})
