// list is an Array with Objects
// term is the searchstring
// min chatacters of the searchstring for the filter to take effect

export default function filterByValues(list, term, min) {
    if (!list) return [];
    if (!term) return list;
    if (!min) min = 3;
    if (term.length < 3) return list;

    // TODO add support for nested object, array, ...

    function object_processor(o) {
        for (let i in o) {
            // keys staring with an underscore are asumed to be internal mogoose document variables
            if (i.startsWith("_")) continue;
            // values might be string, number ...
            let value = String(o[i]);
            if (value.indexOf(term) >= 0) return true;
        }
        return false;
    }

    // so we return the filtered array
    return list.filter(object_processor);
}
