<template>
    <v-row>
        <v-col cols="6">
            <v-menu v-model="datemenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" :label="label" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    @input="
                        datemenu = false;
                        update();
                    "
                ></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="6">
            <v-menu
                ref="time"
                v-model="timemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="time" :label="label" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker
                    v-if="timemenu"
                    v-model="time"
                    full-width
                    @click:minute="
                        $refs.time.save(time);
                        update();
                    "
                    format="24hr"
                ></v-time-picker>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import * as datelib from "@/vuetiform-components/vuetiform-datelib.js";

export default {
    name: "vuetiform-timestamp",
    props: ["value", "label"],
    data: function () {
        let date = new Date();
        if (this.value) {
            date = new Date(this.value);
        }
        return {
            date: datelib.datepickerDate(date),
            time: datelib.timepickerDate(date),
            datemenu: false,
            timemenu: false,
        };
    },
    methods: {
        update() {
            let d = new Date(this.date);
            d.setHours(this.time.split(":")[0], this.time.split(":")[1]);
            this.$emit("input", Math.round(d.getTime() / 1000));
        },
    },
};
</script>
