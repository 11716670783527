export function datepickerDate(date) {
    if (!date) date = new Date();

    var month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    var day = date.getDate();
    if (day < 10) day = "0" + day;

    return date.getFullYear() + "-" + month + "-" + day;
}

export function timepickerDate(date) {
    if (!date) date = new Date();

    var hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    var min = date.getMinutes();
    if (min < 10) min = "0" + min;

    return hour + ":" + min;
}
