// This is the application-module level routes file.
// Override the content according to your needs.

// it is possible to load dynamic componentgroup routes
// import componentgroup_routes from "@/componentgroup_routes.js";

//const routes = [];

//*
// for example, create a default route to home
const routes = [
   /* 
    {
      	name: "home",
        path: "/",
        redirect: "/home",
    },
    */
];
//*/

// if used with dynamic componentgroup routes
// export default routes.concat(componentgroup_routes("app-components"));
export default routes;
