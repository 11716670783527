<template>
    <div v-if="getItems().length > 0">
        <div v-for="(i, ix) in getList()">
            <p id="label" class="font-weight-thin text-left">{{ getLabel(ix) }}</p>
            <v-radio-group v-model="selection[ix]">
                <v-radio v-for="(c, cx) in getItems()" @click="click(c)" :label="stringFormat(c)" :value="getValue(i, c)" :disabled="readonly" hide-details :key="cx"> </v-radio>
            </v-radio-group>
            <br />
        </div>
    </div>
</template>

<script>
export default {
    // note that value is not really processed for now.

    name: "vuetiform-radios",
    props: ["value", "label", "items", "multiple", "readonly", "action", "list", "default"],
    data: function () {
        let selection = [];

        if (this.value !== undefined) if (Array.isArray(this.value)) this.value.forEach((e) => selection.push(e));

        if (this.default !== undefined) {
            if (this.list)
                this.list.forEach((i) => {
                    const vals = selection.filter((e) => e.startsWith(i + "|"));
                    if (vals.length === 0) selection.push(i + "|" + this.default);
                });
            else selection.push(this.default);
        }

        return {
            selection: selection,
        };
    },
    methods: {
        update() {
            this.$emit("input", this.selection);
        },
        getItems() {
            if (this.items) return this.items;
            if (this.value) return this.value;
            return [];
        },
        // the list creates groups
        getList() {
            if (this.list) return this.list;
            return [this.label];
        },
        getLabel(x) {
            if (this.list) return this.list[x];
            return this.label;
        },
        // values are either prefixed with list, or as they would normally be
        getValue(i, c) {
            if (this.list) return i + "|" + c;
            return c;
        },
        stringFormat(i) {
            if (typeof i === "object") if (i.text !== undefined) return i.text;
            return i;
        },
        click(e) {
            if (typeof this.action === "function") this.action(e);

            if (this.readonly) return;
            this.$emit("input", this.selection);
        },
    },
};
</script>
<style scoped>
#label {
    margin-bottom: 0;
}
</style>
