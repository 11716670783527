
import * as axios from "__axios";


export default function uploadFormData(formData) {
    formData.append("path", this.getPath());
    this.in_progress = true;
    this.error = null;
    axios
        .post("/vuetifiles-upload.json", formData)
        .then((response) => {
            if (response.status === 200) this.$emit("input", response.data);
            this.in_progress = false;
        })
        .catch((error) => {
            console.log(error);
            this.error = error;
            this.in_progress = false;
        });
}
