<template>
    <v-main id="main">
        <transition name="component-fade" mode="out-in">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </transition>
    </v-main>
</template>

<script>
export default {
    name: "mainpage",
};
</script>

<style scoped>
#main {
    margin-top: 64px;
}

.component-fade-enter-active,
.component-fade-leave-active {
    transition: opacity 0.1s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0.2;
}
</style>
