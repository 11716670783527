export default function setDocumentProperty(doc) {

    if (!doc._id) return;

    let payload = {};
    // compose the payload
    payload.db = this.db;

    payload.op = "findOneAndUpdate";
    payload.doc = doc;

    // send the event and close the dialog once the answer is ack
    this.$socket.client.emit("database-operation", payload, function (result) {
        if (result.error) alert(result.error);
    });
    return;
}
