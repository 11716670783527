<template>
    <div class="text-xs-center">
        <v-dialog v-model="dialog" max-width="500">
            <v-img :src="require('@/login-assets/login.jpg')">
                <v-layout column fill-height>
                    <v-card-title class="darked">
                        <v-btn dark icon v-if="selected !== 'selector'" v-on:click="selected = 'selector'"> <v-icon>chevron_left</v-icon> </v-btn>
                        <v-btn dark icon v-if="selected === 'selector' && is_user" v-on:click="selected = 'settings'"> <v-icon>fas fa-cog</v-icon> </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn dark icon v-on:click="set_dialog('close')" v-if="isDialog"> <v-icon>cancel</v-icon> </v-btn>
                    </v-card-title>

                    <v-spacer></v-spacer>

                    <v-card-title class="darked white--text pl-5 pt-5">
                        <div class="headline">{{ profile_email || title }}</div>
                        <v-spacer></v-spacer>
                        <v-btn v-if="is_user" dark icon v-on:click="logout()" title="Logout"> <v-icon>fas fa-sign-out-alt</v-icon> </v-btn>
                        <v-btn v-if="!is_user" dark icon v-on:click="toggle_rem()" title="##&en remember me on this computer ##&hu emlékezzem rám ez a gép ##">
                            <v-icon v-if="!rem">fas fa-square</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-layout>
            </v-img>

            <v-layout wrap>
                <v-flex xs12>
                    <v-card height="320">
                        <transition name="component-fade" mode="out-in"> <component :is="selected" @dialog_handler="set_dialog" v-bind:rem="rem" /> </transition>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-dialog>
    </div>
</template>

<script>
// the login dialog is loaded on App.vue so it can work as a dialog

// the selector list
import login_layout from "@/login-components/LoginLayout.vue";

import selector from "@/login-components/LoginSelector.vue";

// LoginCard contents
import password_login from "@/login-components/LoginCardPasswordLogin.vue";
import password_settings from "@/login-components/LoginCardPasswordSettings.vue";
import email_login from "@/login-components/LoginCardEmailLogin.vue";
import email_settings from "@/login-components/LoginCardEmailSettings.vue";
import settings from "@/login-components/LoginCardSettings.vue";
import enter_name from "@/login-components/LoginCardName.vue";

import * as axios from "__axios";

// this.$refs.login_dialog.set_dialog('selector');

export default {
    name: "login-dialog",
    data() {
        return {
            // open the dialog on the login uri path
            dialog: this.$route.path === "/login",
            selected: "selector",
            rem: true,
            isDialog: this.withDialog || true,
        };
    },
    props: {
        withDialog: "",
    },
    components: {
        login_layout,
        selector,
        password_login,
        password_settings,
        email_login,
        email_settings,
        settings,
        enter_name
    },
    methods: {
        open() {
            this.selected = "selector";
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        set_dialog(arg) {
            this.selected = "selector";
            if (arg === "close") return (this.dialog = false);
            this.selected = arg;
        },
        logout() {
            this.$store.dispatch("server/clear_session");

            var _this = this;
            axios({
                method: "post",
                url: "/post-logout.json",
                data: {},
            })
                .then(function (response) {
                    _this.$store.dispatch("server/clear_session");
                    _this.$store.dispatch("socket/disconnect");
                    _this.set_dialog("selector");
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.log(error);
                });
        },
        toggle_rem() {
            this.rem = !this.rem;
        },
    },
    computed: {
        title() {
            if (this.$store.state.server.session.passport)
                if (this.$store.state.server.session.passport.user) {
                    return "##&en Logged in ##&hu Bejelentkezve ##";
                }
            return "##&en Login ##&hu Bejelentkezés ##";
        },
        is_user() {
            if (this.$store.state.server.session) if (this.$store.state.server.session.passport) if (this.$store.state.server.session.passport.user) return true;
            return false;
        },
        profile_email() {
            if (this.$store.state.server.session)
                if (this.$store.state.server.session.user)
                    if (this.$store.state.server.session.user.profile)
                        if (this.$store.state.server.session.user.profile.email) return this.$store.state.server.session.user.profile.email;
            return undefined;
        },
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit("finished");
        },
    },
};
</script>
<style>
.component-fade-enter-active,
.component-fade-leave-active {
    transition: opacity 0.1s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0.2;
}
.darked {
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
