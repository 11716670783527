import Vue from "vue";

const state = {
    //latitude: 0,
    //longitude: 0,
    //altitude: 0,
};

const mutations = {
    setLocation(state, location) {
        Vue.set(state, "latitude", location.latitude || 0);
        Vue.set(state, "longitude", location.longitude || 0);
        Vue.set(state, "altitude", location.altitude || 0);
        //global.f0x.log("setLocation");
    },
};

const actions = {
    async getLocation({ commit, state }) {
        //console.log("GPS getLocation()");

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                	console.log(position.coords);
                    commit("setLocation", position.coords);
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    },
};

const getters = {
    getLocation(state, getters, rootState, rootGetters) {
        return {
            latitude: state.latitude,
            longitude: state.longitude,
            altitude: state.altitude,
        };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
