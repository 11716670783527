<template>
    <div id="database" :key="db">
        <database-card v-if="db" :database="db" />
        <section v-if="!db" class="relative dark-bg">
            <v-container fill-height dark>
                <v-layout>
                    <v-row justify="center" no-gutters>
                        <v-col md="6" lg="5" xl="3" cols="12" class="pa-5 desc-cols" v-for="db in getList()" :key="db">
                            <v-card hover style="max-width: 360px; width: 360px; margin: auto" color="primary" @click.native="onClick(db)">
                                <div class="pa-5 img-cont">
                                    <v-responsive :aspect-ratio="10 / 1">
                                        <v-icon>{{ getIcon(db) }}</v-icon> {{ $store.getters["database/getDatabaseModule"](db) }} </v-responsive>
                                    <p></p>
                                </div>
                                <v-card-title class="text-center text-uppercase" style="display: block">{{ db }}</v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-container>
        </section>
    </div>
</template>
<script>
// TODO needs vuetiform

import DatabaseCard from "@/database-components/database-card.vue";

export default {
    name: "database",
    route: { path: "/database/:db?/:id?", icon: "fas fa-database", toolbar: true, sidebar: true, homepage: true, usecase: "database", open: "/database" },
    components: { DatabaseCard },
    data() {
        return {
            db: null,
            selected: {},
        };
    },
    created() {
        this.checkURI();
    },
    methods: {
        onClick(db) {
            this.db = db;
            if (this.$route.path !== "/database/" + db) this.$router.push("/database/" + db);
        },
        checkURI() {
            if (this.$route.path === "/database" || this.$route.path === "/database/") this.db = null;
            if (this.$route.params.db) if (this.$store.getters["database/getDatabaseList"].includes(this.$route.params.db)) this.db = this.$route.params.db;
        },
        getList() {
            const list = [];
            for (const module of this.$store.getters["database/getDatabaseModules"])
                for (const db of this.$store.getters["database/getDatabaseListByModule"](module)) list.push(db);

            const dba = this.$store.state.server.session.databases;
            if (dba) return list.filter((e) => Object.values(dba[e]).includes(true));

            return list;
        },
        getIcon(db) {
            let icon = "mdi-database";
            const dba = this.$store.state.server.session.databases;
            if (!dba) return icon;

            if (dba[db].read) icon = "mdi-database-outline";
            if (dba[db].comment) icon = "mdi-database-edit-outline";
            if (dba[db].modify) icon = "mdi-database-edit";

            return icon;
        },
    },
    watch: {
        "$route.params"(to, from) {
            this.checkURI();
        },
        "$route.params.db"(to, from) {
            this.checkURI();
        },
        "$store.state.database"() {
            this.checkURI();
        },
    },
};
</script>

<style scoped></style>
