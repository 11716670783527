<template>
    <div class="home">
        <timeline db="missions" sortimenter="users" property="manager" ref="timeline" :methods="{ getColor }" />
    </div>
</template>

<script>
import timeline from "@/timeline-components/timeline.vue";

export default {
    name: "Missions",
    route: { path: "/missionstimeline", icon: "mdi-pentagon", toolbar: true, sidebar: true, homepage: true, usecase: "missionstimeline", open: "/missionstimeline" },
    components: { timeline },
    mounted() {
        this.$refs.timeline.update();
    },
    methods: {
        getColor(e) {
            let color = this.$vuetify.theme.themes.light.primary;
            if (e.active) return color + "99";
            return "#77777777";
        },
    },
    watch: {
        "$store.state.database.missions": function (newVal, oldVal) {
        this.$refs.timeline.update();
        },
        "$store.state.database.locations": function (newVal, oldVal) {
        this.$refs.timeline.update();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#home {
}
</style>
