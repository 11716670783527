import * as axios from "__axios";

export default function getServerFiles() {
    this.in_progress = true;
    this.error = null;
    let formData = new FormData();
    formData.append("path", this.getPath());
    axios
        .post("/vuetifiles-files.json", formData)
        .then((response) => {
            if (response.status === 200) this.updateFilesWith(response.data);
            this.in_progress = false;
        })
        .catch((error) => {
            console.log(error);
            this.error = error;
            this.in_progress = false;
        });
}
