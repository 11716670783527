export default function saveImage() {
    this.dialog = false;
    const result = this.$refs.cropper.getResult();
    var mimeType = this.img_src.split(",")[0].split(":")[1].split(";")[0];
    result.canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("files", blob, this.filename);
        this.uploadFormData(formData);
    }, mimeType);
    // we need to pass mimetype so that the file size is not increased
}
