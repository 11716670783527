<template>
    <v-card flat id="timeline-card">
        <database-document-dialog :db="db" :document="selected" v-model="dialog" :key="key" />
        <timeline-viewlist-dialog v-model="viewlist_dialog" :list="viewlist" @update="update()" />

        <div id="timeline">
            <div id="timeline-d3" />
            <div id="timehead-d3" />
        </div>

        <v-bottom-navigation id="timeline-bottom-naviugation" fixed background-color="primary">
            <v-btn>
                <date-selector v-model="startdate" v-on:update:date="update_by_date()" />
            </v-btn>
            <v-btn @click="startStopClick()">
                <v-icon dark> {{ playing ? "mdi-stop-circle" : "mdi-play-circle" }} </v-icon>
            </v-btn>
            <v-btn @click="draggable = !draggable">
                <v-icon dark> {{ draggable ? "mdi-pin-off" : "mdi-pin" }} </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="zoomTime(1.28)" :disabled="timespan < 10">
                <v-icon dark> mdi-magnify-plus </v-icon>
            </v-btn>
            <v-btn @click="zoomTime(0.8)" :disabled="timespan > 200000000">
                <v-icon dark> mdi-magnify-minus </v-icon>
            </v-btn>

            <v-select dense style="max-width: 150px" :items="timespan_items" v-model="timespan" solo @change="update()"></v-select>

            <v-btn @click="zoomHeight(1.25)">
                <v-icon dark> mdi-magnify-plus-outline </v-icon>
            </v-btn>
            <v-btn @click="zoomHeight(0.8)" :disabled="height < 40">
                <v-icon dark> mdi-magnify-minus-outline </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab dark color="primary" @click="openViewlist()"> <v-icon dark> mdi-view-list </v-icon></v-btn>
            <v-btn class="mx-2" fab dark color="primary" @click="edit()" :disabled="!selected">
                <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
            <v-btn class="mx-2" fab dark color="primary" @click="add()">
                <v-icon dark> mdi-plus </v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-card>
</template>

<script>
import timeline_d3 from "@/timeline-d3/timeline.d3.js";

import DateSelector from "@/timeline-components/timeline-date-selector.vue";
const lang_locale = "##&en en-GB ##&hu hu-HU ##";

import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";
import TimelineViewlistDialog from "@/timeline-components/timeline-viewlist-dialog.vue";
import TimelineTimestampItems from "@/timeline-components/timeline-timestamp-items.mjs";
import getDataset from "@/timeline-components/getDataset.mjs";
import getViewlist from "@/timeline-components/getViewlist.mjs";
import setDocumentProperty from "@/timeline-components/setDocumentProperty.mjs";
import getColor from "@/timeline-components/getColor.mjs";

export default {
    name: "timeline-component",
    components: { DateSelector, DatabaseDocumentDialog, TimelineViewlistDialog },
    props: {
        db: String,
        sortimenter: String,
        property: String,
        methods: Object,
        defaultrowheight: Number,
    },
    data() {
        let timespan = 604800;
        let startdate = new Date(new Date().getTime - (timespan / 2) * 1000);

        // override methods passed
        Object.keys(this.methods).forEach((method) => (this[method] = this.methods[method]));

        return {
            startdate: startdate,
            timefrom: Math.floor(startdate.getTime() / 1000),
            timespan: timespan,
            timespan_items: TimelineTimestampItems(),
            height: this.defaultrowheight || 100,

            timeline_d3: null,
            playing: false,
            dataset: [],
            selected: null,
            intervall: null,

            document: {},
            dialog: false,
            key: 0,
            draggable: false,

            viewlist_dialog: false,
            viewlist: [],
        };
    },
    mounted() {
        this.timeline_d3 = timeline_d3(this);
        this.update();
    },
    methods: {
        startStopClick() {
            this.playing = !this.playing;
            if (!this.playing) return;
            this.startdate = new Date();
        },

        getViewlist,
        getDataset,
        getColor,
        update() {
            // refresh the selected data
            if (this.selected) {
                const selected_id = this.selected._id;
                this.selected = this.$store.getters["database/getDocumentById"](this.db, selected_id);
            }

            this.getDataset(this);
            this.getViewlist(this);

            if (this.timeline_d3) this.timeline_d3.render();
        },
        update_by_date() {
            this.timefrom = Math.floor(this.startdate.getTime() / 1000 - this.timespan / 2);
            this.update();
        },
        clickOnElement(d) {
            if (!d._id) return this.deselect();

            if (!this.selected) {
                this.selected = d;
                //this.update();
                return;
            }

            if (this.selected._id !== d._id) {
                this.selected = d;
                //this.update();
                return;
            }
        },
        setDocumentProperty,
        edit() {
            this.key++;
            this.dialog = true;
        },
        add() {
            this.selected = {
                date: new Date((1 + this.timefrom) * 1000),
                duration: this.timespan-1,
            };
            this.key++;
            this.dialog = true;
        },
        openViewlist() {
            this.getViewlist();
            this.viewlist_dialog = true;
        },
        deselect() {
            this.selected = null;
            this.update();
        },
        onTimelineDragstarted() {
            this.playing = false;
        },
        zoomTime(p) {
            const center = Math.round(this.timefrom + this.timespan / 2);
            this.timespan = Math.round(this.timespan / p);
            this.timefrom = Math.round(center - this.timespan / 2);
            this.update();
        },
        zoomHeight(p) {
            this.height = Math.round(this.height * p);
            this.update();
        },
    },
};
</script>
<style scoped>
#timeline-card {
    width: 100%;
}

#timeline {
}
/*#timehead-d3 {
    background-color: blue;
    position: fixed;
    top: 64px;
    right: 0;
    width: 100%;
    height: 44px;
    z-index: 15;
}*/
#timeline-d3 {
    background-color: lightgray;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
#timeline-actions {
    z-index: 20;
}
#timeline-bottom-naviugation {
    z-index: 20;
}
</style>
