<template>
    <div class="eventlog">
        <v-btn v-if="!$store.state.server.session.user" text v-on:click="$root.login_dialog.open()"> Login </v-btn>
        <timeline v-if="$store.state.server.session.user" db="eventlog" sortimenter="locations" property="location" ref="timeline" :methods="{ getDataset }" />
    </div>
</template>

<script>
import timeline from "@/timeline-components/timeline.vue";
import getDataset from "@/assetlog/getDataset.mjs";

export default {
    name: "Assetlog",
    route: { path: "/assetlog", icon: "mdi-chart-timeline", toolbar: true, sidebar: true, homepage: true, usecase: "assetlog", open: "/assetlog" },
    components: { timeline },
    mounted() {
        this.$refs.timeline.update();
    },
    methods: {
        getDataset,
    },
    watch: {
        "$store.state.database.eventlog": function (newVal, oldVal) {
            this.$refs.timeline.update();
        },
        "$store.state.database.locations": function (newVal, oldVal) {
            this.$refs.timeline.update();
        },
    },
};
</script>
