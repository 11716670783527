var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"pa-10":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getItems(),"search":_vm.search,"footer-props":{
            'items-per-page-options': [25, 50, 100, 500, -1],
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
        },"item-key":"_id","show-select":"","dense":""},on:{"click:row":_vm.open},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-card-actions',[(_vm.addEnabled())?_c('database-csv-upload',{key:_vm.db + '-csv-upload',attrs:{"db":_vm.db}}):_vm._e(),_c('database-csv-download',{key:_vm.db + '-csv-download',attrs:{"db":_vm.db,"items":_vm.selected}}),_c('v-btn',{staticClass:"mx-2 ma-10",attrs:{"fab":"","color":"primary","disabled":_vm.selected.length < 1},on:{"click":function($event){return _vm.remove()}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1),_c('v-spacer'),(_vm.addEnabled())?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":_vm.open}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()],1),_c('database-document-dialog',{key:_vm.key,attrs:{"db":_vm.db,"document":_vm.document},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }