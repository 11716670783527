// DEPRECATED

export default function csv_converter(str, headerList, quotechar = '"', delimiter = ",") {
    const cutlast = (_, i, a) => i < a.length - 1;
    // const regex = /(?:[\t ]?)+("+)?(.*?)\1(?:[\t ]?)+(?:,|$)/gm; // no variable chars
    const regex = new RegExp(`(?:[\\t ]?)+(${quotechar}+)?(.*?)\\1(?:[\\t ]?)+(?:${delimiter}|$)`, "gm");
    const lines = str.split("\n");
    const headers = headerList || lines.splice(0, 1)[0].match(regex).filter(cutlast);
    const list = [];

    for (const line of lines) {
        const val = {};
        for (const [i, m] of [...line.matchAll(regex)].filter(cutlast).entries()) {
            // Attempt to convert to Number if possible, also use null if blank
            let key = headers[i];
            if (key.endsWith(",")) key = key.slice(0, -1);
            val[key] = m[2].length > 0 ? Number(m[2]) || m[2] : null;
        }
        list.push(val);
    }

    return list;
}
