<template>
    <v-row>
        <v-col cols="6">
            <v-text-field v-model="formatted_duration" :label="label"  readonly disabled></v-text-field>
        </v-col>
        <v-col cols="3">
            <v-text-field v-model="input" type="number" prepend-icon="mdi-circle-slice-3" @input="update" style="text-align: right" :rules="validate"></v-text-field>
        </v-col>
        <v-col cols="3">
            <v-select v-model="unit" :items="units" @input="change_unit"></v-select>
        </v-col>
    </v-row>
</template>

<script>
// in seconds
export default {
    name: "vuetiform-duration",
    props: ["value", "label"],
    data: function () {
        let prop_value;
        if (this.value) prop_value = 0 + this.value;
        return {
            units: [
                { text: "##&en days ##&hu nap ##", value: 86400 },
                { text: "##&en hours ##&hu óra ##", value: 3600 },
                { text: "##&en minutes ##&hu perc ##", value: 60 },
                { text: "##&en seconds ##&hu másodperc ##", value: 1 },
            ],
            input: Math.floor((prop_value || 0) / 60),
            duration: prop_value,
            unit: 60,
            validate: [ß.validateNumber],
            modified: false
        };
    },
    methods: {
        change_unit() {
            if (!this.modified) this.input = Math.floor(this.duration / this.unit);
            this.modified = true;
        },
        update() {
            if (isNaN(this.input)) return (this.duration = undefined);
            this.modified = true;
            this.duration = this.input * this.unit;
            this.$emit("input", this.duration);
        },
    },
    computed: {
        formatted_duration: function () {
            if (this.duration === undefined) return '"##&en Empty field ##&hu Üres mező ##"';
            let str = [];
            let val = this.duration;
            for (let i of this.units) {
                if (val >= i.value) {
                    str.push(Math.floor(val / i.value) + " " + i.text);
                    val = val % i.value;
                }
            }
            return str.join(" ");
        },
    },
};
</script>
<style scoped>
.v-text-field__slot input {
    text-align: right;
}
</style>
