<template>
    <v-form v-model="valid" ref="form" v-on:submit.prevent="noop">
        <v-text-field label="##&en Name ##&hu Név ##" v-model="name" :rules="nameRules" required></v-text-field>
        <v-btn :disabled="!valid" v-show="btn_title" @click="submit">{{ btn_title }}</v-btn>
    </v-form>
</template>

<script>
export default {
    name: "form-for-name",
    props: ["btn_title"],
    data() {
        var default_name = "";
        if (ß.DEBUG) if (ß.MODE === "development") default_name = ß.HOSTNAME.split('.')[0] + " Admin";

        return {
            valid: false,
            name: default_name,
            nameRules: [
                // fires all the time
                //  message-transition-enter-active message-transition-enter-to
                v => !!v || "...", //"E-mail is required",
            ]
        };
    },
    methods: {
        submit() {
            //this.$refs.form.validate();
            this.$emit("return", this.name);
        },
        noop() {}
    }
};
</script>