export default function getDataset() {
    if (!this.$store.getters["database/hasDatabase"](this.sortimenter)) return [];
    if (!this.$store.getters["database/hasDatabase"](this.db)) return [];

    const placeholder = { _id: undefined, name: "?", show: true };

    const viewlist = this.viewlist;
    const rowslist = [placeholder];

    const sortiment = this.$store.getters["database/getDatabaseArrayData"](this.sortimenter) || [];
    sortiment.forEach((e) => {
        if (viewlist.filter((x) => x._id === e._id && x.show === true).length > 0) rowslist.push(e);
    });

    this.dataset = rowslist
        .map((l) => {
            const o = { ...l };
            let count = 0;
            // sortimenter property
            o.items = this.$store.getters["database/getDatabaseArrayData"](this.db)
                .filter((d) => d[this.property] === l._id)
                .map((d) => {
                    const o = { ...d };
                    o.color = this.getColor(o);
                    o.z = count++;
                    return o;
                });

            return o;
        })
        .filter((e) => e.items.length > 0);

    //let maxcount = Math.max(...this.dataset.map((e) => e.items.length));
    return;
}
